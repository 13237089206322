import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useLegend } from "../hooks/useLegend";
import { LegendSeries } from "src/components/Chart/types";
import { defaultChartColors } from "src/components/Chart/constants";
import { IconButton } from "@mui/material";
import { InfoOutlined, OpenInNewOutlined } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "src/constants/routes/app-routes";
import ApiPath from "src/constants/ApiPath";

interface ILegendItemProps {
  item: LegendSeries & { key: string };
  index: number;
  isVisible: boolean;
  toggleIndividualSeriesVisibility: (name: string) => void;
  hasNewDesign: boolean;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    item: {
      "-webkit-column-break-inside": "avoid",
      "column-break-inside": "avoid",
      whiteSpace: "nowrap",
      display: "inline-block",
    },
    itemHidden: {
      "&:hover": {
        color: "#337ab7",
      },
    },
    itemContent: {
      display: "flex",
      alignItems: "center",
      paddingRight: 6,
      "&:hover $openNewIcon": {
        visibility: "visible",
      },
    },
    label: {
      userSelect: "none",
      fontSize: 12,
      fontFamily: `"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif`,
      color: "#337ab7",
      "&:hover": {
        color: "#23527c",
      },
      cursor: "pointer",
    },
    labelHidden: {
      color: "#cccccc",
    },
    symbolHidden: {
      backgroundColor: "#cccccc !important",
    },
    symbol: {
      width: 12,
      height: 12,
      marginRight: 2,
      flexShrink: 0,
    },
    line: {
      height: 2,
    },
    openNewIcon: {
      height: "18px",
      visibility: "hidden",
    },
  })
);

const LegendItem = ({ item, index, isVisible, toggleIndividualSeriesVisibility, hasNewDesign }: ILegendItemProps) => {
  const classes = useStyles();
  const { columnWidth, seriesTooltips } = useLegend();
  const history = useHistory();

  const handleClick = () => toggleIndividualSeriesVisibility(item.name);

  const handleLinkClick = () => {
    const tableStateStorageKey = `${ApiPath.api.watchRTCRoom}-table-state`;
    localStorage.removeItem(tableStateStorageKey);
    const match = item.name.match(/^\d+/);
    const number = match ? parseInt(match[0], 10) : null;

    if (number) {
      const filterModel = {
        expectations: {
          values: [`Insight${number}`],
          filterType: "set",
        },
      };

      history.push(AppRoutes.WatchRTCHistory, filterModel);
    }
  };

  let tooltipTitle = seriesTooltips[item.name];

  if (!tooltipTitle) {
    // maybe it's not exact name
    // so try to use indexOf
    const keys = Object.keys(seriesTooltips);
    const foundKey = keys.find((k) => item.name.indexOf(k) !== -1);
    if (foundKey) {
      tooltipTitle = seriesTooltips[foundKey];
    }
  }

  if (!tooltipTitle) {
    tooltipTitle = "";
  }

  let color = item.options?.color || item.color;

  if (!color) {
    color = defaultChartColors[index % defaultChartColors.length];
  }

  let resultJsx: JSX.Element;

  if (hasNewDesign) {
    resultJsx = (
      <Grid item>
        <Tooltip title={tooltipTitle}>
          <Grid
            container
            onClick={handleClick}
            wrap="nowrap"
            spacing={1}
            sx={{ alignItems: "center", cursor: "pointer", userSelect: "none" }}
          >
            <Grid item>
              <Box
                sx={{ width: "12px", height: "12px", borderRadius: "2px", bgcolor: isVisible ? color : "#cccccc" }}
              />
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontSize: 11, fontWeight: 400, lineHeight: 1, color: isVisible ? "#6D7278" : "#cccccc" }}
              >
                {item.name}
              </Typography>
            </Grid>
          </Grid>
        </Tooltip>
      </Grid>
    );
  } else {
    resultJsx = (
      <Tooltip title={tooltipTitle}>
        <div
          key={item.key}
          className={`${classes.item} ${!isVisible ? classes.itemHidden : ""}`}
          style={{
            columnWidth,
          }}
        >
          <div className={classes.itemContent}>
            <div
              onClick={handleClick}
              className={`${classes.symbol} ${!isVisible ? classes.symbolHidden : ""}`}
              style={{
                backgroundColor: color,
              }}
            />
            <div onClick={handleClick} className={`${classes.label} ${!isVisible ? classes.labelHidden : ""}`}>
              {item.name}
            </div>
            {item.link && (
              <Tooltip title="View in histoy" disableInteractive placement="top">
                <IconButton
                  onClick={() => {
                    handleLinkClick();
                  }}
                  size="small"
                  className={classes.openNewIcon}
                >
                  <OpenInNewOutlined />
                </IconButton>
              </Tooltip>
            )}

            {item.link && (
              <Tooltip title="Learn more about the insight." disableInteractive placement="top">
                <IconButton
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(item.link, "_blank");
                  }}
                  size="small"
                  className={classes.openNewIcon}
                >
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </Tooltip>
    );
  }

  return resultJsx;
};

export default LegendItem;
