// tslint:disable:no-any
import { RouteComponentProps, withRouter } from "react-router";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Icon, CircularProgress } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material";

import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";

type StyledComponent = WithStyles<
  "card" | "divider" | "toolbar" | "tableRow" | "tableContainer" | "link" | "alignIcon" | "loadingIcon"
>;

export interface QuickLinksProps {
  webRtcAnalytics: WebRtcAnalytics;
  getDumpFile: () => void;
  replayAsWatchRTC?: () => void;
  isLoading: boolean;
  isAdmin: boolean;
  hideReplay?: boolean;
  isFramed?: boolean;
}

const QuickLinks = (props: StyledComponent & QuickLinksProps & RouteComponentProps<{}>) => {
  const {
    classes,
    webRtcAnalytics,
    getDumpFile,
    replayAsWatchRTC,
    isLoading,
    isAdmin,
    hideReplay = false,
    isFramed,
  } = props;
  return (
    <Fade in={true}>
      <Grid item={true} xs={12}>
        <Card className={classes.card}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="subtitle1">Connection Quick Links</Typography>
            {!isFramed ? (
              <Grid container style={{ width: "unset" }} spacing={2} alignItems={"center"}>
                {!hideReplay && isAdmin && (
                  <Button variant="contained" style={{ marginRight: 5 }} onClick={replayAsWatchRTC}>
                    Replay as WatchRTC
                  </Button>
                )}
                <Button variant="contained" onClick={getDumpFile} disabled={isLoading}>
                  Download
                  {isLoading ? (
                    <CircularProgress className={classes.loadingIcon} />
                  ) : (
                    <Icon className={classes.alignIcon}>file_download</Icon>
                  )}
                </Button>
              </Grid>
            ) : null}
          </Toolbar>
          <Divider className={classes.divider} />
          <Grid container={true} spacing={5} className={classes.tableContainer}>
            {Object.keys(webRtcAnalytics.PeerConnections).map((connection: string) => {
              return (
                <a key={connection} className={classes.link} href={`#${connection}`}>
                  <Typography variant="subtitle1">{connection}</Typography>
                </a>
              );
            })}
          </Grid>
        </Card>
      </Grid>
    </Fade>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    tableRow: {
      cursor: "pointer",
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
    },
    tableContainer: {
      padding: `0 ${theme.spacing(3)}`,
      overflowY: "hidden",
      overflowX: "auto",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      cursor: "pointer",
      padding: theme.spacing(2),
    },
    alignIcon: {
      marginLeft: "5px",
      cursor: "pointer",
    },
    loadingIcon: {
      marginLeft: "5px",
      width: "24px !important",
      height: "24px !important",
      color: "rgba(0, 0, 0, 0.26)",
      animation: "none",
    },
  });

const decorate = withStyles(styles);

export default withRouter(decorate(QuickLinks));
