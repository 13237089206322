import { Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import useIsElementOverflowing from "src/hooks/useIsElementOverflowing";

interface CustomKeyValueProps {
  keyName: string;
  value: string | { id: string; label: string; endpoint: string };
  tablePageLink?: string;
}

const useStyles = makeStyles(
  createStyles({
    link: {
      minWidth: 0,

      color: "#366EB6",
      textDecoration: "none",

      "&:hover": { textDecoration: "underline" },
    },
    value: {
      margin: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",

      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 400,
      letterSpacing: "0.17px",

      "$link:only-child &": {
        lineHeight: "24px",
      },
    },
    copyButton: { marginLeft: 8 },
  })
);

const CustomKeyValue = ({ keyName, value, tablePageLink }: CustomKeyValueProps) => {
  const classes = useStyles();

  const { elementRef: valueRef, isOverflowing: isValueOverflowing } = useIsElementOverflowing<HTMLParagraphElement>();

  let resultJsx: JSX.Element;

  let finalValue = "";
  let tooltipTitle = "";
  let href: string | undefined;

  if (typeof value === "string") {
    const filterOption = {
      [`keys.${keyName}`]: {
        filterType: "set",
        values: [value],
      },
    };
    const filter = JSON.stringify(filterOption);
    finalValue = value;
    tooltipTitle = isValueOverflowing ? finalValue : "";
    href = tablePageLink && `${tablePageLink}/?filterModel=${encodeURIComponent(filter)}`;
  } else {
    finalValue = value.label;
    tooltipTitle = isValueOverflowing ? finalValue : "";
    href = tablePageLink && `${tablePageLink}/${value.endpoint}/${value.id}`;
  }

  resultJsx = (
    <Tooltip title={tooltipTitle} arrow key={finalValue}>
      <p ref={valueRef} className={classes.value}>
        {finalValue}
      </p>
    </Tooltip>
  );

  if (href) {
    resultJsx = (
      <a href={href} target="_blank" rel="noreferrer" className={classes.link}>
        {resultJsx}
      </a>
    );
  }

  return resultJsx;
};

export default CustomKeyValue;
